import { easings } from "@react-spring/web";
import { useCallback } from "react";
import { animated, useSprings } from "react-spring";
import styled from "styled-components";
import {
  CONTAINER_WIDTH_MEDIUM,
  CONTAINER_WIDTH_SMALL,
  TEXT_COLOR,
} from "./constants";
import Menu from "./Menu";

const name = [
  { letter: "d" },
  { letter: "a" },
  { letter: "n" },
  { letter: "i" },
  { letter: "e" },
  { letter: "l" },
  { letter: "g" },
  { letter: "y" },
  { letter: "l" },
  { letter: "l" },
  { letter: "e" },
  { letter: "n" },
  { letter: "s" },
  { letter: "a" },
  { letter: "n" },
  { letter: "d" },
];

const altName = [
  { letter: "c" },
  { letter: "o" },
  { letter: "m" },
  { letter: "p" },
  { letter: "u" },
  { letter: "t" },
  { letter: "e" },
  { letter: "r" },
  { letter: "e" },
  { letter: "m" },
  { letter: "o" },
  { letter: "t" },
  { letter: "i" },
  { letter: "o" },
  { letter: "n" },
  { letter: "s" },
];

const Header = () => {
  const [springs, setSprings] = useSprings(16, (i) => ({
    scale: 1,
    opacity: 1,
  }));

  const [secondarySprings, setSecondarySprings] = useSprings(16, (i) => ({
    scale: 0.3,
    opacity: 0,
  }));

  const onMouseOver = useCallback(() => {
    setSprings.start((i) => ({
      scale: 0.3,
      opacity: 0,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));

    setSecondarySprings.start((i) => ({
      scale: 1,
      opacity: 1,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));
  }, [setSprings, setSecondarySprings]);

  const onMouseLeave = useCallback(() => {
    setSprings.start((i) => ({
      scale: 1,
      opacity: 1,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));

    setSecondarySprings.start((i) => ({
      scale: 0.3,
      opacity: 0,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));
  }, [setSprings, setSecondarySprings]);

  return (
    <Wrapper>
      <Container>
        <Content>
          <TextContainer onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Title>
              {name.map((o, i) => (
                <animated.span
                  key={i}
                  style={{
                    display: "inline-block",
                    marginRight: i === 5 ? 5 : 0,
                    ...springs[i],
                  }}
                >
                  {o.letter}
                </animated.span>
              ))}
            </Title>
            <SecondaryTitle>
              {altName.map((o, i) => (
                <animated.span
                  key={i}
                  style={{
                    display: "inline-block",
                    marginRight: i === 7 ? 5 : 0,
                    ...secondarySprings[i],
                  }}
                >
                  {o.letter}
                </animated.span>
              ))}
            </SecondaryTitle>
          </TextContainer>
          <Menu />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.header`
  width: 100%;
  z-index: 1;
  pointer-events: none;
`;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH_MEDIUM}px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  position: relative;
  pointer-events: all;
  margin-bottom: 60px;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    flex-direction: row;
    margin-bottom: 30px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  padding: 10px 20px 0 20px;
  overflow: hidden;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    padding: 30px 20px;
  }
`;

const Title = styled.h1`
  cursor: default;
  color: ${TEXT_COLOR};
  margin: 0;
  font-size: 42px;
`;

const SecondaryTitle = styled.h1`
  cursor: default;
  color: ${TEXT_COLOR};
  margin: 0;
  font-size: 42px;
  position: absolute;
  top: 10px;
  left: 20px;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    top: 30px;
  }
`;
