import styled from "styled-components";
import {
  CONTAINER_WIDTH_SMALL,
  TEXT_COLOR,
  TRANSITION_TIME,
} from "./constants";

const Menu = () => {
  return (
    <List>
      <ListItem>
        <ListLink
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/computeremotion"
        >
          #twitter
        </ListLink>
      </ListItem>
      <ListItem>
        <ListLink
          target="_blank"
          rel="noreferrer"
          href="https://www.fxhash.xyz/u/Daniel%20Gyllensand"
        >
          #fxhash
        </ListLink>
      </ListItem>
      <ListItem>
        <ListLink
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/computeremotion"
        >
          #instagram
        </ListLink>
      </ListItem>
    </List>
  );
};

export default Menu;

const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  font-size: 24px;
  list-style: none;
  position: relative;
  overflow: hidden;
  padding: 30px 20px 0 20px;
  pointer-events: auto;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    padding: 36px 20px;
    font-size: 32px;
    justify-content: flex-end;
  }
`;

const ListItem = styled.li`
  &:not(:first-of-type) {
    margin-left: 10px;
  }

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }
`;

const ListLink = styled.a`
  color: ${TEXT_COLOR};
  text-decoration: none;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translateY(-50%) scaleX(0);
    background-color: ${TEXT_COLOR};
    width: 110%;
    height: 3px;
    transition: transform ${TRANSITION_TIME};
  }

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    &:hover {
      &:after {
        transform: translateY(-50%) scaleX(1);
      }
    }
  }
`;
