import { useMemo, useRef, useState } from "react";
import { GenerativeToken } from "./types";
import {
  BORDER_COLOR,
  COLOR_BLUE,
  COLOR_RED,
  COLOR_YELLOW,
  CONTAINER_WIDTH_SMALL,
  TEXT_COLOR,
  TRANSITION_TIME,
} from "./constants";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import { ASSETS } from "./App";
import Thumbnail from "./Thumbnail";

export function getGeneratedUrl(uri: string) {
  const gateway = "https://gateway.fxhash.xyz/ipfs/";
  return gateway + uri.substring(7);
}

const GET_PROJECT_INFO = gql`
  query Query($generativeTokenId: Float) {
    generativeToken(id: $generativeTokenId) {
      objkts {
        metadata
      }
    }
  }
`;

interface GraphResponse {
  generativeToken: {
    objkts: GenerativeToken[];
  };
}

const descriptionRegex = /^(.*?)[.?!]\s/;

const openLiveVersion = (uri: string, name: string) => {
  const hasCorrectPath = uri.indexOf("/?");

  if (hasCorrectPath > -1) {
    window.open(getGeneratedUrl(uri), name, "width=600,height=600");
  } else {
    const output = uri.replace(/\?/g, "/?");
    window.open(getGeneratedUrl(output), name, "width=600,height=600");
  }
};

const Project = ({
  id,
  name,
  metadata,
  objktsCount,
  index,
}: GenerativeToken & { index: number }) => {
  const { data } = useQuery<GraphResponse>(GET_PROJECT_INFO, {
    variables: {
      generativeTokenId: id,
    },
  });

  const { objkts } = data?.generativeToken || { objkts: [] };
  const { displayUri, description } = metadata;
  const [assetLoaded, setAssetLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const shuffledObjects = useMemo(
    () =>
      objkts.length
        ? objkts
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
        : new Array(2).fill({}),
    [objkts]
  );

  const shortDescription = useMemo(
    () => descriptionRegex.exec(description)?.[0] || "",
    [description]
  );
  const videoAsset = useMemo(() => ASSETS.find((o) => o.id === id), [id]);

  return (
    <Wrapper>
      <Top index={index}>
        <Meta index={index}>
          {objktsCount > 0 ? `${objktsCount} editions` : `Coming soon`}
        </Meta>
        <Title
          index={index}
          href={`https://www.fxhash.xyz/generative/${id}`}
          target="_blank"
        >
          {name}
        </Title>
      </Top>
      <Container index={index}>
        <Hero index={index}>
          <ImageContainer index={index}>
            <ImageContent>
              {videoAsset ? (
                <Video
                  ref={videoRef}
                  autoPlay
                  muted
                  loop
                  playsInline
                  isLoaded={assetLoaded}
                  onClick={() => openLiveVersion(metadata.artifactUri, name)}
                  onCanPlay={() => {
                    setAssetLoaded(true);
                  }}
                >
                  <source src={videoAsset.video} type="video/mp4" />
                </Video>
              ) : (
                <Image
                  isLoaded={assetLoaded}
                  src={getGeneratedUrl(displayUri)}
                  alt={metadata.name}
                  onLoad={() => setAssetLoaded(true)}
                  onClick={() => openLiveVersion(metadata.artifactUri, name)}
                ></Image>
              )}
            </ImageContent>
          </ImageContainer>
          <Bottom index={index}>
            <div>
              <Link
                index={index}
                href={`https://www.fxhash.xyz/generative/${id}`}
                target="_blank"
              >
                #Read more
              </Link>
            </div>
            <Description index={index}>{shortDescription}</Description>
          </Bottom>
        </Hero>

        <Content hasItems={objktsCount > 0}>
          {objkts.length
            ? shuffledObjects
                .map(({ value }) => value)
                .filter((o, i) => i <= 1)
                .map((object, i) => (
                  <Thumbnail
                    key={i}
                    metadata={object?.metadata}
                    name={name}
                    index={index}
                    onClick={() =>
                      openLiveVersion(object?.metadata.artifactUri, name)
                    }
                  />
                ))
            : null}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Project;

const Wrapper = styled.section`
  color: ${TEXT_COLOR};
  margin-bottom: 50px;
  padding-bottom: 20px;
`;

const Top = styled.div<{ index: number }>`
  display: flex;
  flex-direction: ${({ index }) => (index % 2 !== 0 ? "row-reverse" : "row")};
  justify-content: ${({ index }) =>
    index % 2 !== 0 ? "flex-end" : "flex-start"};
`;

const Bottom = styled.div<{ index: number }>`
  display: flex;
  flex-direction: ${({ index }) => (index % 2 === 0 ? "row-reverse" : "row")};
  justify-content: flex-end;
`;

const Meta = styled.div<{ index: number }>`
  font-size: 24px;
  margin: 0;
  padding: ${({ index }) =>
    index % 2 !== 0 ? "27px 0 27px 0" : "27px 0 27px 0"};
  text-align: center;
  text-transform: uppercase;
  margin-left: ${({ index }) => (index % 2 !== 0 ? "10px" : "auto")};
  margin-right: ${({ index }) => (index % 2 === 0 ? "10px" : "auto")};
  color: ${TEXT_COLOR};
  text-decoration: none;
  align-items: flex-end;
  display: flex;
  white-space: nowrap;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    font-size: 32px;
    padding: 27px 10px;
  }
`;

const Title = styled.a<{ index: number }>`
  font-size: 36px;
  margin: 0;
  padding: 20px 10px;
  width: 100%;
  text-align: center;
  border-top: 3px solid ${BORDER_COLOR};
  border-right: 3px solid ${BORDER_COLOR};
  border-left: 3px solid ${BORDER_COLOR};
  text-transform: uppercase;
  background-color: ${COLOR_RED};
  color: #ffffff;
  text-decoration: none;
  display: block;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    width: 50%;
    font-size: 42px;
  }
`;

const Description = styled.p<{ index: number }>`
  font-size: 24px;
  margin: 0;
  width: 70%;
  padding: 20px;
  border-bottom: 3px solid ${BORDER_COLOR};
  border-right: 3px solid ${BORDER_COLOR};
  border-left: 3px solid ${BORDER_COLOR};
  background-color: ${COLOR_BLUE};
  color: #ffffff;
`;

const Link = styled.a<{ index: number }>`
  border-bottom: 3px solid ${BORDER_COLOR};
  border-right: ${({ index }) =>
    index % 2 === 0 ? `3px solid ${BORDER_COLOR}` : "none"};
  border-left: ${({ index }) =>
    index % 2 !== 0 ? `3px solid ${BORDER_COLOR}` : "none"};
  font-size: 24px;
  padding: 20px;
  background-color: ${COLOR_YELLOW};
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: ${TEXT_COLOR};
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%) scaleX(0);
    background-color: ${TEXT_COLOR};
    width: calc(100% - 30px);
    height: 3px;
    transition: transform ${TRANSITION_TIME};
  }

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    &:hover {
      &:after {
        transform: translateY(-50%) scaleX(1);
      }
    }
  }
`;

const Container = styled.div<{ index: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    flex-direction: ${({ index }) => (index % 2 !== 0 ? "row" : "row-reverse")};
  }
`;

const Hero = styled.div<{ index: number }>`
  width: 100%;
  display: flex;
  align-items: ${({ index }) => (index % 2 === 0 ? "flex-start" : "flex-end")};
  flex-direction: column;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    width: 65%;
  }
`;

const Content = styled.div<{ hasItems: boolean }>`
  width: 35%;
  display: none;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    display: block;

    ${({ hasItems }) =>
      !hasItems &&
      ` display: flex;
        align-items: flex-start;
        justify-content: flex-end;`}
  }
`;

const ImageContainer = styled.div<{ index: number }>`
  border: 3px solid ${BORDER_COLOR};
  padding: 4%;
  width: 100%;
  background-color: #ffffff;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    transition: padding ${TRANSITION_TIME};

    &:hover {
      padding: 0;
    }
  }
`;

const ImageContent = styled.div`
  padding-bottom: 100%;
  overflow: hidden;
  height: 0;
`;

const Image = styled.img<{ isLoaded: boolean }>`
  cursor: pointer;
  display: block;
  width: 100%;
  transition: opacity ${TRANSITION_TIME};
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const Video = styled.video<{ isLoaded: boolean }>`
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  transition: opacity ${TRANSITION_TIME};
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;
