import styled from "styled-components";
import { getGeneratedUrl } from "./Project";
import {
  CONTAINER_WIDTH_SMALL,
  BORDER_COLOR,
  TRANSITION_TIME,
} from "./constants";
import { MetaData } from "./types";
import { useState } from "react";

const Thumbnail = ({
  metadata,
  name,
  index,
  onClick,
}: {
  metadata?: MetaData;
  name: string;
  index: number;
  onClick: () => void;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ThumbnailContainer isLoaded={isLoaded} index={index}>
      <ThumbnailContent>
        {metadata && (
          <ThumbnailImage
            isLoaded={isLoaded}
            onClick={onClick}
            src={getGeneratedUrl(metadata.thumbnailUri)}
            alt={metadata.name}
            onLoad={() => setIsLoaded(true)}
          />
        )}
      </ThumbnailContent>
    </ThumbnailContainer>
  );
};

export default Thumbnail;

const ThumbnailContainer = styled.div<{ index: number; isLoaded: boolean }>`
  position: relative;
  pointer-events: ${({ isLoaded }) => (isLoaded ? `auto` : `none`)};
  display: flex;
  padding: 7%;
  border-top: 3px solid ${BORDER_COLOR};
  border-right: ${({ index }) =>
    index % 2 !== 0 ? `3px solid ${BORDER_COLOR}` : "none"};
  border-left: ${({ index }) =>
    index % 2 !== 0 ? "none" : `3px solid ${BORDER_COLOR}`};
  background-color: #ffffff;

  &:last-of-type {
    border-bottom: 3px solid ${BORDER_COLOR};
  }

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    width: 100%;
    transition: padding ${TRANSITION_TIME};

    &:hover {
      padding: 0;
    }
  }
`;

const ThumbnailContent = styled.div`
  width: 100%;
  padding-bottom: 100%;
  height: 0;
`;

const ThumbnailImage = styled.img<{ isLoaded: boolean }>`
  cursor: pointer;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;
