export const BG_COLOR = "#faf0e6";
export const TEXT_COLOR = "#45413e";
export const BORDER_COLOR = "#45413e";
export const SHADOW_COLOR = "#999999";
export const COLOR_RED = "#e34b4e";
export const COLOR_BLUE = "#1a6eb1";
export const COLOR_YELLOW = "#f4f400";
export const CONTAINER_WIDTH_MEDIUM = 1025 + 40;
export const CONTAINER_WIDTH_SMALL = 660 + 40;
export const TRANSITION_TIME = "0.15s";
